<template>
  <div class="user">
    <div class="automatic_label" v-for="(item, index) in form" :key="index">
      <el-form v-loading="loading" ref="form" :model="item" label-width="80px">
        <div class="automatic_label_one">
          <div class="automatic_label_one_left">
            <i class="el-icon-setting"></i>
            <span class="inco_txt">① 设置 匹配 基本信息</span>
          </div>
          <div class="automatic_label_one_center">
            <div class="automatic_label_one_center_top">
              <div>
                <div class="arithmetic_input">
                  <el-form-item label="算法名称">
                    <el-input v-model="item.arithmetic" placeholder="请输入算法名称"></el-input>
                  </el-form-item>
                </div>
                <el-form-item label="推送阈值">
                  <div class="input_number">
                    <el-input-number
                      v-model="item.pushThresholdMax"
                      :min="0"
                      :max="100"
                      label="描述文字"
                    ></el-input-number>
                    <div class="input_number_color">
                      <span
                        >此阈值需设置分数的最小值，如分数
                        <span style="color: #4e93fb">大于</span>
                        此阈值，则
                        <span style="color: #4e93fb">自动推送实体</span>
                      </span>
                    </div>
                    <el-input-number
                      v-model="item.pushThresholdMin"
                      :min="0"
                      :max="100"
                      label="描述文字"
                    ></el-input-number>
                    <div class="input_number_color">
                      <span
                        >此阈值设置需规约分数的最小值，如分数，则
                        <span style="color: #4e93fb">小于</span>
                        此阈值，则
                        <span style="color: #4e93fb">暂不推送实体</span>
                      </span>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="存储阈值">
                  <el-input-number
                    v-model="item.saveThreshold"
                    :min="1"
                    :max="100"
                    label="描述文字"
                  ></el-input-number>
                  <div class="input_number_color">
                    <span
                      >此阈值设置需规约分数的最小值，如分数
                      <span style="color: #4e93fb">小于</span>
                      此阈值，则
                      <span style="color: #4e93fb">暂不储存此数据</span>
                    </span>
                  </div>
                </el-form-item>
                <el-form-item label="是否启用">
                  <el-switch v-model="item.isEnable"> </el-switch>
                </el-form-item>
              </div>
              <div class="version_number" v-if="index == 0 && activeId">
                <span @click="Showversion = true">版本号：{{ item.versionNumber }}</span>
                <span class="sanjiao_box"></span>
                <div v-if="Showversion" class="version_number_po">
                  <div
                    @click="selectVersion(el)"
                    class="version_number_flex"
                    v-for="(el, index) in item.versionResults"
                    :key="index"
                  >
                    {{ el.versionNumber }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="automatic_label_one">
          <div class="automatic_label_one_left" style="height: 282px">
            <i class="el-icon-success"></i>
            <span class="inco_txt">② 选择实体</span>
          </div>
          <div class="automatic_label_one_center">
            <div class="automatic_label_one_center_top" style="height: 271px">
              <div class="entity_one">
                <div class="entity_one_top">
                  <span>实体1</span>
                </div>
                <el-form-item label="实体类型:" prop="entityOne">
                  <el-radio-group v-model="entityOne">
                    <el-radio
                      @change="entityOneval(el.codeName, item)"
                      v-for="(el, index) in entityType"
                      :key="index"
                      :label="el.codeName"
                      >{{ el.codeName }}</el-radio
                    >
                  </el-radio-group>
                </el-form-item>

                <el-form-item v-if="entityOne == '数智动态'" label="动态类型:" prop="entityOne">
                  <el-radio-group v-model="item.entityOne">
                    <el-radio
                      v-for="(item, index) in trendsTpe"
                      :key="index"
                      :label="item.codeName"
                      >{{ item.codeName }}</el-radio
                    >
                  </el-radio-group>
                </el-form-item>
              </div>
              <div class="entity_one">
                <div class="entity_one_top">
                  <span>实体2</span>
                </div>
                <el-form-item label="实体类型:" prop="entityTwo">
                  <el-radio-group v-model="entityTwo">
                    <el-radio
                      @change="entityTwoval(el.codeName, item)"
                      v-for="(el, index) in entityType"
                      :key="index"
                      :label="el.codeName"
                      >{{ el.codeName }}</el-radio
                    >
                  </el-radio-group>
                </el-form-item>
                <el-form-item v-if="entityTwo == '数智动态'" label="动态类型:" prop="entityOne">
                  <el-radio-group v-model="item.entityTwo">
                    <el-radio
                      v-for="(item, index) in trendsTpe"
                      :key="index"
                      :label="item.codeName"
                      >{{ item.codeName }}</el-radio
                    >
                  </el-radio-group>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
        <div class="automatic_label_one">
          <div class="automatic_label_one_left" style="height: 270px">
            <i class="el-icon-s-claim"></i>
            <span class="inco_txt">③ 选择标签组</span>
          </div>
          <div class="automatic_label_one_center">
            <div class="automatic_label_one_center_top">
              <div>
                <div class="select_label">
                  <div>
                    <span>选择标签组</span>
                  </div>
                  <div class="ckeckbox_text">
                    <el-checkbox
                      :indeterminate="(e = item.isIndeterminate)"
                      v-model="item.checkAll"
                      @change="handleCheckAllChange(item)"
                      >全选</el-checkbox
                    >
                  </div>
                </div>
                <div>
                  <div class="label_trips">
                    <i style="color: #f53939" class="el-icon-s-opportunity"></i>
                    <span
                      >当前已勾选的标签组已分配权重
                      <span style="color: #4e93fb">{{ item.steoWight }}</span>
                      ，可分配权重
                      <span style="color: #4e93fb">{{ item.approveWight }}</span>
                    </span>
                  </div>
                  <div class="el_checkbox_flex">
                    <el-checkbox-group v-model="item.labellist">
                      <div class="checkbox_group_flex" v-if="item.codelist.length">
                        <div
                          class="checkbox_group_top"
                          v-for="(el, indexs) in item.codelist"
                          :key="indexs"
                        >
                          <el-checkbox @change="groupType(el)" :label="el.groupLabelType">
                            <div v-if="!el.list.length">
                              <el-button style="color: #606266" type="text"
                                >{{ el.groupLabelType
                                }}<i
                                  @click="labelClick(el, index)"
                                  v-if="el.flag"
                                  class="el-icon-edit po_edit"
                                ></i
                              ></el-button>
                            </div>
                            <el-tooltip v-else placement="bottom">
                              <div slot="content">
                                <div style="padding: 5px" v-for="o in el.list" :key="o.id">
                                  {{ o.labelLevel }}级标签：{{ o.labelLevelWeight }}
                                </div>
                              </div>
                              <el-button style="color: #606266" type="text"
                                >{{ el.groupLabelType }}
                                <i
                                  @click="labelClick(el, index)"
                                  v-if="el.flag"
                                  class="el-icon-edit po_edit"
                                ></i
                              ></el-button>
                            </el-tooltip>
                          </el-checkbox>

                          <el-input
                            @input="inputWight(item, el)"
                            :disabled="!el.flag"
                            oninput="value=value.replace(/[^\d.]/g, '')"
                            style="margin-top: 5px"
                            placeholder="请输入0.1~1的数值"
                            v-model="el.groupLabelWeight"
                          >
                          </el-input>
                          <el-button
                            v-if="el.isExceed && el.flag && el.groupLabelWeight"
                            icon="el-icon-warning-outline"
                            class="exceed_po"
                            type="text"
                            >权重已超出{{ item.exceed }}</el-button
                          >
                        </div>
                      </div>
                    </el-checkbox-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-form>
      <div class="buttom_save">
        <div class="save_buttom" v-if="!activeId || index > 0" @click="clickSave(item)">
          <span>保存</span>
        </div>
        <div v-if="index == 0 && activeId" class="save_buttom" @click="saveCopy(item)">
          <i class="el-icon-document-copy"></i>
          <span style="margin-left: 5px">复制</span>
        </div>
        <!-- <div v-if="!item.isSave&&activeId" class="save_buttoms" @click="saveCopy(item)">
        <i class="el-icon-document-copy"></i>
        <span style="margin-left: 5px;">保存并复制</span>
      </div> -->
        <div v-if="index > 0" class="save_buttoms" @click="saveCopy(item)">
          <i class="el-icon-document-copy"></i>
          <span style="margin-left: 5px">复制</span>
        </div>
        <div class="cancel button" @click="clickCancel">取消</div>
      </div>
    </div>
    <el-dialog
      title="编辑权重"
      :visible.sync="dialogVisible"
      width="42%"
      @close="handleClose"
      center
    >
      <div class="form_name">
        <el-form ref="form" :model="weightInfo" :rules="weightRules" label-width="150px">
          <el-form-item :label="groupLabel" prop="weight">
            <el-input
              @change="weightgroub"
              @input="inputweight"
              oninput="value=value.replace(/[^\d.]/g, '')"
              v-model="weightInfo.groupLabelWeight"
              placeholder="请输入0.01-1的数值"
            />
          </el-form-item>
          <el-form-item label="是否开启结算层级" prop="">
            <div>
              <el-radio-group style="position: relative;top:-9px" @change="radiochange" v-model="weightInfo.hierarchicalSettlementState">
              <el-radio label="1">是</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>
            </div>

           <div>
            <el-input-number
            @change="lementchange"
            v-if="weightInfo.hierarchicalSettlementState==1"
              :min="0"
              :max="10"
              :step="1"
              v-model="weightInfo.settlementHierarchy"
              placeholder="请输入层级数值"
            />
           </div>
          </el-form-item>

          <el-form-item label="设置层级：" prop="groupLabelLevel">
            <el-input-number
              @change="handleChange"
              :min="0"
              :max="7"
              :step="1"
              v-model="weightInfo.groupLabelLevel"
              placeholder="请输入层级数值"
            />
          </el-form-item>
          <el-form-item v-if="weightInfo.groupLabelLevel > 0" label="设置权重：" prop="apiPath">
            <div class="weight_flex">
              <div class="weight_flex_item" v-for="(el, index) in weightInfo.list" :key="index">
                <span>{{ index + 1 }}级标签</span>
                <el-input
                  @blur="inputlabelWight(weightInfo, el)"
                  oninput="value=value.replace(/[^\d.]/g, '')"
                  v-model="el.labelLevelWeight"
                  placeholder="请输入0.01-1的数值"
                />
                <el-button
                  v-if="el.isExceed && el.labelLevelWeight && weightInfo.exceed > 0"
                  icon="el-icon-warning-outline"
                  class="exceed_po"
                  style="color: #ff6c6c"
                  type="text"
                  >权重已超出{{ weightInfo.exceed }}</el-button
                >
              </div>
            </div>
          </el-form-item>
          <el-form-item label="" prop="">
            <div class="label_trips" style="position: relative; top: -20px">
              <span
                >当前所有层级已分配权重
                <span style="color: #4e93fb">{{ weightInfo.steoWight }}</span>
                ，层级可分配权重
                <span style="color: #4e93fb">{{ weightInfo.approveWight }}</span>
              </span>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
        <!-- <el-button @click="dialogVisible.menu = false">取 消</el-button> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { insertArithmetic, selectById, updateArithmetic } from '@/api/matemanage.js'
import { getCode } from '@/api/demand.js'
import { getGroupLabel } from '@/api/content.js'
export default {
  computed: {
    activeId() {
      return this.$route.query.id
    }
  },
  data() {
    return {
      dialogVisible: false,
      weightRules: {
        // groupLabelLevel: [{ required: true, message: '请输入', trigger: 'change' }],
        // weight: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      form: [
        {
          checkAll: false, //全选
          isIndeterminate: false,
          codelist: [], //标签组集合
          steoWight: 0, //已有权重
          approveWight: 1, //可权重
          exceed: 0, //超出权重
          labellist: [], //选中标签
          arithmetic: '', //算法名
          arithmeticVersionResult: {
            //编辑新增新算法时，前端传过来的最新版本号的信息
            id: '', //算法主键
            versionNumber: '' //版本号
          },
          entityOne: '', //主实体名
          entityTwo: '', //副实体名
          groupLabelType: '', //该数据不用展示
          // id: '',/主键
          isEnable: true, //是否启用
          paramList: [],
          pushThresholdMax: 0, //推送阈值,达到该值则推送数据
          pushThresholdMin: 0, //不推送阈值,小于该值不用推送大于存储阈值
          saveThreshold: 0, //存储阈值
          versionNumber: '', //版本号
          versionResults: [
            //各个版本号
            {
              id: '', //算法主键
              versionNumber: '' //版本号
            }
          ]
        }
      ],
      Showversion: false, //选择历史版本号
      loading: false,
      entityOne: '', //实体1
      entityTwo: '', //实体2
      entityType: [], //实体类型
      trendsTpe: [], //动态类型
      ids: [],
      weightInfo: {},
      formindex: 0,
      groupLabel: ''
      // labellist: [] //已选择标签
    }
  },
  created() {
    if (this.activeId) {
      this.selectById()
    } else {
      this.getGroupLabel()
    }
    this.getCode017()
  },
  methods: {
    handleClose() {
      this.dialogVisible = false
    },
    radiochange(val){
      if(val==0){
        this.weightInfo.settlementHierarchy=null
      }
      this.weightInfo.list.forEach((el, index) => {
          el.hierarchicalSettlementState = val
        })
    },
    weightgroub(val) {
      this.inputWight(this.form[this.formindex], this.weightInfo)
      if (this.weightInfo.groupLabelLevel) {
        this.weightInfo.list.forEach((el, index) => {
          el.groupLabelWeight = val
        })
      }
    },
    lementchange(val){
      if(this.weightInfo.list.length){
        this.weightInfo.list.forEach((el, index) => {
          el.settlementHierarchy = val
        })
      }
    },
    inputweight(e) {
      let value = e.replace(/[^\d.]/g, '') // 只能输入数字和.
      value = value.replace(/^\./g, '') //第一个字符不能是.
      value = value.replace(/\.{2,}/g, '.') // 不能连续输入.
      value = value.replace(/(\.\d+)\./g, '$1') // .后面不能再输入.
      value = value.replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      value = value.replace(/(\.\d{1})\d*/, '$1') // 最多保留2位小数
      if (value > parseInt(1)) {
        value = null
      }
      this.weightInfo.groupLabelWeight = value
    },

    handleSubmit() {
      if (this.weightInfo.exceed > 0) {
        this.$message.error('层级总权重不能超过该标签组的权重！')
        return
      }
      this.dialogVisible = false
    },
    labelClick(item, index) {
      // console.log(index);
      this.formindex = index
      this.weightInfo = item
      this.weightInfo.approveWight = 1
      this.groupLabel = item.groupLabelType
      if (this.activeId) {
        if (this.weightInfo.list.length) {
          this.inputlabelWight(this.weightInfo, this.weightInfo.list[0])
        }
      }
      this.dialogVisible = true
    },
    handleChange(val) {
      if (val) {
        let num = 0
        let num2 = 0
        if (this.weightInfo.list.length) {
          num = val - this.weightInfo.list.length
          num2 = Math.abs(num)
          if (num2 != 1) {
            this.weightInfo.list = []
            for (var j = 0; j < val; j++) {
              this.weightInfo.list.push({
                groupLabelType: this.weightInfo.groupLabelType,
                arithmeticId: this.weightInfo.arithmeticId,
                groupLabelLevel: this.weightInfo.groupLabelLevel, //标签组层级
                groupLabelWeight: this.weightInfo.groupLabelWeight, //标签组权重
                labelLevel: j + 1, //标签层级
                hierarchicalSettlementState: this.weightInfo.hierarchicalSettlementState,
                settlementHierarchy:this.weightInfo.settlementHierarchy,
                labelLevelWeight: '' //标签组层级对应的权重
              })
            }
          } else {
            if (this.weightInfo.list.length > val) {
              this.weightInfo.list.pop()
            } else {
              this.weightInfo.list.push({
                groupLabelType: this.weightInfo.groupLabelType,
                arithmeticId: this.weightInfo.arithmeticId,
                groupLabelLevel: this.weightInfo.groupLabelLevel, //标签组层级
                groupLabelWeight: this.weightInfo.groupLabelWeight, //标签组权重
                labelLevel:this.weightInfo.list.length+1, //标签层级
                hierarchicalSettlementState: this.weightInfo.hierarchicalSettlementState,
                settlementHierarchy:this.weightInfo.settlementHierarchy,
                labelLevelWeight: '' //标签组层级对应的权重
              })
            }
          }
          this.weightInfo.list.forEach(el=>{
            el.groupLabelLevel=this.weightInfo.groupLabelLevel
          })
        } else {
          this.weightInfo.list = []
          for (var i = 0; i < val; i++) {
            this.weightInfo.list.push({
              groupLabelType: this.weightInfo.groupLabelType,
              arithmeticId: this.weightInfo.arithmeticId,
              groupLabelLevel: this.weightInfo.groupLabelLevel, //标签组层级
              groupLabelWeight: this.weightInfo.groupLabelWeight, //标签组权重
              labelLevel: i + 1, //标签层级
              hierarchicalSettlementState: this.weightInfo.hierarchicalSettlementState,
              settlementHierarchy:this.weightInfo.settlementHierarchy,
              labelLevelWeight: '' //标签组层级对应的权重
            })
          }
        }
      }
    },
    submitForm() {},
    //匹配算法详情
    async selectById(id) {
      this.loading = true
      const query = {
        id: id || this.activeId
      }
      const { data: res } = await selectById(query)
      if (res.resultCode == 200) {
        this.form[0] = res.data
        // this.form[0].labellist=[]
        this.$set(this.form[0], 'labellist', [])
        this.$set(this.form[0], 'codelist', []) //标签组集合
        this.$set(this.form[0], 'steoWight', 0) //已有权重
        this.$set(this.form[0], 'steoWight', 1) //可权重
        this.$set(this.form[0], 'exceed', 0) //超出权重
        this.getGroupLabel() //回显标签组
        let list1 = ''
        let list2 = ''
        if (this.form[0].entityTwo) {
          list2 = this.form[0].entityTwo.split('-')
        }
        if (this.form[0].entityOne) {
          list1 = this.form[0].entityOne.split('-')
        }
        if (list1[0] == '数智动态') {
          this.entityOne = list1[0]
          this.form[0].entityOne = list1[1]
          this.getCode018()
        } else {
          //回显实体1
          this.entityOneval(this.form[0].entityOne, res.data)
        }
        if (list2[0] == '数智动态') {
          this.entityTwo = list2[0]
          this.form[0].entityTwo = list2[1]
          this.getCode018()
        } else {
          //回显实体2
          this.entityTwoval(this.form[0].entityTwo, res.data)
        }
      }
    },
    //标签组新
    async getCode017() {
      const query = {
        codeType: '017'
      }
      const { data: res } = await getCode(query)
      if (res.resultCode == 200) {
        this.entityType = res.data
      }
    },
    async getCode018() {
      const query = {
        codeType: '018'
      }
      const { data: res } = await getCode(query)
      if (res.resultCode == 200) {
        this.trendsTpe = res.data
      }
    },
    //选择实体1
    entityTwoval(val, item) {
      this.entityTwo = val
      if (val == '数智动态') {
        this.getCode018()
      } else {
        item.entityTwo = val
      }
    },
    //选择实体2
    entityOneval(val, item) {
      this.entityOne = val
      if (val == '数智动态') {
        this.getCode018()
      } else {
        item.entityOne = val
      }
    },
    //选择版本
    selectVersion(el) {
      this.codelist = [] //清空标签
      this.selectById(el.id)
      this.Showversion = false
    },
    forbchange() {
      this.$forceUpdate() //强制刷新
    },
    //计算权重
    inputWight(item, el) {
      // console.log(item);
      // let value = el.groupLabelWeight.replace(/[^\-\d.]/g, "") // 只能输入数字和.
      // value = value.replace(/^\./g, '')  //第一个字符不能是.
      // value = value.replace(/\.{2,}/g, '.') // 不能连续输入.
      // value = value.replace(/(\.\d+)\./g, '$1') // .后面不能再输入.
      // value = value.replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      // value = value.replace(/(\.\d{1})\d*/, '$1')// 最多保留2位小数
      // el.groupLabelWeight = value
      let allweight = 0
      item.codelist.forEach((el, index) => {
        if (el.groupLabelWeight) {
          allweight = parseFloat(allweight) + parseFloat(el.groupLabelWeight)
          if (el.list.length) {
            el.list.forEach((o) => {
              o.groupLabelWeight = el.groupLabelWeight
            })
          }
        }
      })
      if (allweight > 1) {
        el.isExceed = true
        item.exceed = parseFloat(allweight) - parseFloat(1)
        item.exceed = item.exceed.toFixed(2)
        item.approveWight = 0 //可用权重
        item.steoWight = allweight.toFixed(2)
      } else {
        item.approveWight = parseFloat(1) - parseFloat(allweight)
        item.approveWight = item.approveWight.toFixed(2)
        item.steoWight = allweight.toFixed(2)
        item.exceed = 0
        el.isExceed = false
      }
      this.$forceUpdate() //强制刷新
    },
    inputlabelWight(item, el) {
      // console.log(el);
      // console.log(item);
      let allweight = 0

      item.list.forEach((el, index) => {
        if (el.labelLevelWeight) {
          allweight = parseFloat(allweight) + parseFloat(el.labelLevelWeight)
        }
      })
      if (allweight > parseFloat(1)) {
        el.isExceed = true
        item.exceed = parseFloat(allweight) - parseFloat(1)
        item.exceed = item.exceed.toFixed(2)
        item.approveWight = 0 //可用权重
        item.steoWight = allweight.toFixed(2)
      } else {
        item.approveWight = parseFloat(1) - parseFloat(allweight)
        item.approveWight = item.approveWight.toFixed(2)
        item.steoWight = allweight.toFixed(2)
        item.exceed = 0
        item.list.forEach((o) => {
          o.isExceed = false
        })
      }
    },
    //标签类别
    async getGroupLabel() {
      const res = await getGroupLabel()
      if (res.data.resultCode == 200) {
        let list = res.data.data
        this.form[0].codelist = []
        // console.log(this.form[0]);
        list.forEach((el, index) => {
          this.form[0].codelist.push({
            flag: false, //判断勾选输入权重
            isExceed: false, //判断权重是否超出
            groupLabelType: el,
            steoWight: 0,
            approveWight: '',
            arithmeticId: '',
            groupLabelLevel: '', //标签组层级
            groupLabelWeight: '', //标签组权重
            labelLevel: '', //标签层级
            labelLevelWeight: '', //标签组层级对应的权重
            list: [],
            settlementHierarchy:null,
            hierarchicalSettlementState:'1'
          })
        })

        if (this.activeId) {
          let list = []
          this.form[0].codelist.forEach((el, indexs) => {
            if (this.form[0].labelWeightResults) {
              this.form[0].labelWeightResults.forEach((o) => {
                if (el.groupLabelType == o.groupLabelType) {
                 
                  el.list = o.arithmeticLabelWeightList
                  el.groupLabelLevel = o.groupLabelLevel
                  el.groupLabelWeight = o.groupLabelWeight
                  el.hierarchicalSettlementState=o.hierarchicalSettlementState
                  el.settlementHierarchy=o.settlementHierarchy
                  this.form[0].codelist[indexs].flag = true
                  list.push(el.groupLabelType)
                  this.inputWight(this.form[0], this.form[0].codelist[0])
                }
              })
            }
          })
          this.form[0].labellist = list
          // this.form[0].paramList.forEach((el, index) => {
          //   this.form[0].codelist.forEach((item, indexs) => {
          //     if (
          //       this.form[0].paramList[index].groupLabelType ==
          //       this.form[0].codelist[indexs].groupLabelType
          //     ) {
          //       this.form[0].codelist[indexs].groupLabelWeight =
          //         this.form[0].paramList[index].groupLabelWeight
          //       this.form[0].codelist[indexs].flag = true
          //       this.form[0].labellist.push(this.form[0].codelist[indexs].groupLabelType)
          //       this.inputWight(this.form[0], this.form[0].paramList[index])
          //     }
          //   })
          // })
        }
        this.loading = false
      } else {
        this.loading = false
        this.$message.error(res.data.message)
      }
    },
    //选择标签
    groupType(val) {
      // console.log(this.form[0].labellist)
      val.flag = !val.flag
      this.$forceUpdate() //强制刷新
    },
    //全选标签
    handleCheckAllChange(el) {
      this.form.paramList = []
      if (el.checkAll) {
        el.codelist.forEach((item) => {
          this.ids.push(item.groupLabelType)
          el.labellist.push(item.groupLabelType)
          item.flag = !item.flag
        })
      } else {
        el.codelist.forEach((item) => {
          this.ids = []
          el.labellist = []
          item.flag = !item.flag
        })
      }
      el.isIndeterminate = el.checkAll
    },
    //复制
    saveCopy(item) {
      var obj = JSON.parse(JSON.stringify(item))
      // console.log(obj);
      this.form.push(obj)
    },
    clickCancel() {
      // this.$router.go(-1)
      this.$router.push({
        path: '/system/matemanage',
        query: {
          pageNum: this.$route.query.pageNum
        }
      })
    },
    // 保存
    clickSave(item) {
      if (item.steoWight > parseFloat(1)) {
        this.$message.error('标签权重不能大于1')
        return
      }
      if (item.steoWight < parseFloat(1)) {
        this.$message.error('标签权重不能小于1')
        return
      }
      item.paramList = []
      item.codelist.forEach((el, index) => {
        if (item.codelist[index].groupLabelWeight) {
          if (item.codelist[index].list.length) {
            item.paramList = [...item.paramList, ...item.codelist[index].list]
          } else {
            item.paramList.push({
              arithmeticId: item.codelist[index].arithmeticId,
              groupLabelLevel: item.codelist[index].groupLabelLevel,
              labelLevelWeight: item.codelist[index].labelLevelWeight,
              labelLevel: item.codelist[index].labelLevel,
              groupLabelType: item.codelist[index].groupLabelType,
              groupLabelWeight: item.codelist[index].groupLabelWeight,
              hierarchicalSettlementState:item.codelist[index].hierarchicalSettlementState,
              settlementHierarchy:item.codelist[index].settlementHierarchy,
            })
          }
        }
      })

      if (!this.activeId) {
        insertArithmetic(item).then((response) => {
          if (response.data.resultCode == 200) {
            this.$message.success('新增成功')
            this.$router.go(-1)
          } else {
            this.$message.error(response.data.message)
          }
        })
      } else {
        this.loading = true
        item.arithmeticVersionResult = item.versionResults[item.versionResults.length - 1]

        updateArithmetic(item).then((response) => {
          if (response.data.resultCode == 200) {
            this.loading = false
            this.$message.success('编辑成功')
            this.selectById()
          } else {
            this.loading = false
            this.$message.error(response.data.message)
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .arithmetic_input .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 731px;
}
::v-deep .checkbox_group_flex .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 140px;
}
::v-deep .checkbox_group_flex .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 140px;
}
::v-deep .el-radio {
  color: #606266;
  font-weight: 500;
  line-height: 1;
  position: relative;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  outline: 0;
  font-size: 16px;
  margin-right: 30px;
  margin-top: 15px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
::v-deep .el-input input::-webkit-input-placeholder {
  color: #aeabab;
  font-size: 10px;
}
::v-deep .el-input input::-moz-input-placeholder {
  color: #aeabab;
  font-size: 10px;
}
::v-deep .el-input input::-ms-input-placeholder {
  color: #aeabab;
  font-size: 10px;
}

::v-deep .el-form-item {
  margin-bottom: 12px;
}
::v-deep .form_name .el-form-item {
  margin-bottom: 22px;
}
::v-deep .form_name .el-input__inner {
  width: 180px;
}
::v-deep .weight_flex_item .el-input__inner {
  width: 135px;
}
.weight_flex {
  display: flex;
  flex-wrap: wrap;
  border: 1px dashed #d4d4d4;
  padding: 0px 10px 10px 10px;
  .weight_flex_item {
    width: 135px;
    margin-right: 10px;
  }
}
.buttom_save {
  display: flex;
  margin-left: 80px;
  .save_buttom {
    margin-top: 10px;
    width: 93px;
    margin-left: 20px;
    height: 43px;
    border-radius: 2px;
    background: #4e93fb;
    color: #fff;
    text-align: center;
    line-height: 43px;
    cursor: pointer;
  }
  .cancel {
    background-color: #fff;
    color: #4e93fb;
    cursor: pointer;
    border: 1px solid #4e93fb;
  }
  .button {
    margin-top: 10px;
    width: 120px;
    height: 41px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
  }
  .save_buttoms {
    margin-top: 10px;
    margin-left: 20px;
    width: 120px;
    height: 43px;
    border-radius: 2px;
    background: #4e93fb;
    color: #fff;
    text-align: center;
    line-height: 43px;

    cursor: pointer;
  }
}

.automatic_label {
  width: 100%;
  border-top: 20px solid #f6f7fb;
  padding: 20px;
  background: #fff;

  /deep/.el-checkbox__inner {
    width: 18px;
    height: 18px;
  }
  /deep/.el-checkbox__label {
    color: #333;
  }
  /deep/.el-checkbox__inner::after {
    height: 10px;
    left: 6px;
  }
  .automatic_label_one {
    display: flex;
    margin-top: 20px;
    .automatic_label_one_left {
      padding-top: 30px;
      height: 385px;
      width: 71px;
      background: #f6f6f6;
      display: flex;
      flex-direction: column;
      align-items: center;
      i {
        font-size: 40px;
        color: #4e93fb;
      }
      .inco_txt {
        display: inline-block;
        width: 20px;
        margin-top: 15px;
      }
    }

    .automatic_label_one_center {
      margin-left: 20px;

      .automatic_label_one_center_top {
        border: 1px solid #c4c4c4;
        width: 1450px;
        padding: 20px;
        height: 350px;
        display: flex;
        justify-content: space-between;
        .label_trips {
          margin-top: 20px;
          color: #bebebe;
          font-size: 12px;
        }
        .el_checkbox_flex {
          width: 1450px;
          height: 350px;
          overflow: auto;
        }
        .checkbox_group_flex {
          display: flex;
          flex-wrap: wrap;

          .checkbox_group_top {
            display: flex;
            flex-direction: column;
            margin-right: 30px;
            margin-top: 20px;
            position: relative;
            .po_edit {
              height: 10px;
              color: #217af7;
              position: absolute;
              right: -20px;
              top: 12px;
            }
          }
        }
        .select_label {
          width: 1430px;
          background: #f6f6f6;
          height: 43px;
          display: flex;
          color: #146aff;
          line-height: 43px;
          padding: 0px 10px;
          font-size: 20px;
          align-items: center;
          .ckeckbox_text {
            margin-left: 20px;
            position: relative;
            top: 1px;
          }
        }
        .version_number {
          background: #f6f6f6;
          width: 180px;
          height: 43px;
          text-align: center;
          line-height: 43px;
          position: relative;
          cursor: pointer;
          pointer-events: all;
          .sanjiao_box {
            position: relative;
            top: 5px;
            left: 10px;
            display: inline-block;
            border-top: 8px solid #8d8d8d;
            border-bottom: 8px solid #f6f6f6;
            border-left: 8px solid #f6f6f6;
            border-right: 8px solid #f6f6f6;
          }
          .version_number_po {
            position: absolute;
            width: 180px;
            height: 280px;
            overflow: auto;
            top: 43px;
            right: 0px;
            box-shadow: 0px 0px 2px 0px #bcbcbc;
            .version_number_flex {
              width: 180px;
              height: 35px;
              text-align: center;
              line-height: 35px;
              cursor: pointer;
            }
            .version_number_flex:hover {
              width: 180px;
              height: 35px;
              text-align: center;
              line-height: 35px;
              color: #4e93fb;
              background: #edf4ff;
            }
          }
        }
        .entity_one {
          width: 700px;
          height: 250px;
          border: 1px dashed #c4c4c4;
          padding: 10px;
          .entity_one_top {
            background: #f6f6f6;
            height: 43px;
            width: 660px;
            line-height: 43px;
            color: #146aff;
            font-size: 20px;
            padding: 0px 20px;
          }
        }
        .input_number {
          display: flex;
          flex-direction: column;
        }
        .input_number_color {
          font-size: 12px;
          color: #bebebe;
        }
      }
    }
  }
  .exceed_po {
    height: 5px;
    color: #ff6c6c;
    font-size: 12px;
  }
}
</style>
