import request from '@/utils/request'

//匹配算法一览
export function arithmeticList(data) {
  return request({
    url: '/arithmetic/arithmeticList',
    method: 'post',
    data
  })
}
//新增算法
export function insertArithmetic(data) {
    return request({
      url: '/arithmetic/insertArithmetic',
      method: 'post',
      data
    })
  }
  //算法详情
  export function selectById(data) {
    return request({
      url: '/arithmetic/selectById',
      method: 'get',
      params:data
    })
  }
  //编辑算法
  export function updateArithmetic(data) {
    return request({
      url: '/arithmetic/updateArithmetic',
      method: 'post',
      data
    })
  }
//是否启用--包括启用和停用
export function updateEnable(data) {
    return request({
      url: '/arithmetic/updateEnable',
      method: 'get',
      params:data
    })
  }
  //是否启用--包括冻结和解冻
  export function updateFreeze(data) {
    return request({
      url: '/arithmetic/updateFreeze',
      method: 'get',
      params:data
    })
  }
  

  




